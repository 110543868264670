import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Row, Col } from 'antd'

import FormattedMessage from 'components/FormattedMessage'
import Icon from 'components/Icon'

const mapStateToProps = (state) => ({
  isMobile: state.layout.mobile,
})

const Badges = (props) => {
  const {
    hasHybridBadge,
    hasRapidFeedback,
    isHighlighted,
    isInstantBookable,
    isMobile,
    isTopPerforming,
  } = props

  if (
    !isHighlighted &&
    !hasRapidFeedback &&
    !isTopPerforming &&
    !isInstantBookable &&
    !hasHybridBadge
  ) {
    return null
  }

  const showRapidFeedback = hasRapidFeedback && !hasHybridBadge
  const showTopPerforming = isTopPerforming && !hasHybridBadge
  const showHighlightedText = !(showRapidFeedback && showTopPerforming)
  const showInstantBookableText = !hasHybridBadge

  return (
    <div className="provider-card__badges">
      <Row type="flex">
        {isHighlighted && (
          <Col className="provider-card__badge">
            <Icon name="star" />
            {showHighlightedText && ( // too long otherwise
              <FormattedMessage id="providerCard.isHighlighted" />
            )}
          </Col>
        )}

        {showRapidFeedback && (
          <Col className="provider-card__badge">
            <Icon name="bolt" />
            <FormattedMessage id="providerCard.hasRapidFeedback" />
          </Col>
        )}

        {showTopPerforming && (
          <Col className="provider-card__badge">
            <Icon name="thumbs-up" />
            <FormattedMessage id="providerCard.isTopPerforming" />
          </Col>
        )}

        {isInstantBookable && (
          <Col className="provider-card__badge">
            <Icon name="instant-booking" />
            {showInstantBookableText && (
              <FormattedMessage id="shared.provider.instantBookable" />
            )}
          </Col>
        )}

        {hasHybridBadge && (
          <Col className="provider-card__badge">
            <Icon name="camera" />
            <FormattedMessage id={isMobile ? 'shared.provider.hybrid.mobile' : 'shared.provider.hybrid'} />
          </Col>
        )}

        <Col className="provider-card__badge-skew">
          <div />
        </Col>
      </Row>
    </div>
  )
}

Badges.propTypes = {
  hasHybridBadge: PropTypes.bool,
  hasRapidFeedback: PropTypes.bool,
  isHighlighted: PropTypes.bool,
  isInstantBookable: PropTypes.bool,
  isMobile: PropTypes.bool,
  isTopPerforming: PropTypes.bool,
}

export default connect(mapStateToProps)(Badges)
