import React from 'react'
import PropTypes from 'prop-types'

import IconList from 'components/IconList'

class AbstractProviderDetails extends React.PureComponent {
  render () {
    const {
      locationTypeInfo,
      guests,
      catering,
      price,
    } = this.props

    const listData = [
      {
        icon: 'locationtype',
        content: locationTypeInfo,
      },
      {
        icon: 'catering',
        content: catering,
      },
    ]

    if (guests) {
      listData.push({
        icon: 'guests',
        content: guests,
      })
    }

    if (price) {
      listData.push({
        icon: 'money-bill-wave-solid',
        content: price,
      })
    }

    return (
      <IconList
        listData={listData}
        className="icon-list--columned icon-list--nowrap"
      />
    )
  }
}

AbstractProviderDetails.propTypes = {
  locationTypeInfo: PropTypes.string,
  guests: PropTypes.node,
  catering: PropTypes.string,
  price: PropTypes.node,
}

export default AbstractProviderDetails
