import React from 'react'

import FormattedMessage from 'components/FormattedMessage'
import IconList from 'components/IconList'

import { MeetingRoomProvider } from 'store/types/ProviderProfilePage'

const EventRoomDetails: React.FC<MeetingRoomProvider> = (props) => {
  const {
    guests,
    cateringOptions,
  } = props

  const listData: Array<ListData> = [
    {
      icon: 'guests',
      content: <FormattedMessage id="eventRoomDetails.guestCount" values={{ count: guests }} />,
    },
  ]

  const includedCatering = cateringOptions
    .filter((option): option is InputCateringOptions => ['beverage_included', 'food_included'].includes(option))

  if (includedCatering.length) {
    const outputCateringOptions: OutputCateringOptions = includedCatering.length > 1
      ? 'food_beverage_included'
      : includedCatering[0]

    const cateringData = {
      icon: 'catering',
      content: <FormattedMessage id={`eventRoomDetails.${outputCateringOptions}`} />,
    }

    listData.push(cateringData)
  }

  return (
    <IconList listData={listData} className="icon-list--columned icon-list--nowrap" />
  )
}

type ListData = {
  icon: string,
  content: JSX.Element,
}

type InputCateringOptions = 'beverage_included' | 'food_included'

type OutputCateringOptions = InputCateringOptions | 'food_beverage_included'

export default EventRoomDetails
