import React, { useState, useEffect, ReactNode, TouchEventHandler } from 'react'
import classNames from 'classnames'
import { Picture, PictureVariants } from 'store/types/ProviderProfilePage'
import uniq from 'lodash/uniq'

import useBrowser from 'hooks/browser'

import { pushDataLayer } from 'helpers/googleAnalytics'
import Icon from 'components/Icon'

import './index.less'

type SlideDirection = 'current-to-prev' | 'current-to-next'

const srcSet = (variants?: PictureVariants, canWebp?: boolean) => {
  if (!variants) {
    return undefined
  }

  return [
    `${variants[canWebp ? 'thumb_webp' : 'thumb']} 480w`,
    `${variants[canWebp ? 'small_webp' : 'small']} 768w`,
    `${variants[canWebp ? 'cropped_webp' : 'cropped']} 1200w`,
  ].join(' ')
}

const Gallery: React.FC<GalleryProps> = ({
  pictures,
  initialPictureIndex = 0,
  title,
  showSlider,
  trackGalleryUsage,
}) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(initialPictureIndex)
  const [slideDirection, setSlideDirection] = useState<SlideDirection>()
  const [touchStartPointX, setTouchStartPointX] = useState<number>()
  const { canWebp } = useBrowser()

  const wrapper = (content?: ReactNode) => <div className="gallery">{content}</div>
  const urlAttribute = canWebp() ? 'webp_url' : 'url'
  const altTag = (index: number) => title ? `${title} ${index + 1}` : undefined

  useEffect(
    () => {
      setCurrentImageIndex(initialPictureIndex)
    },
    [initialPictureIndex],
  )

  if (!pictures?.length) {
    return wrapper()
  }

  if (!showSlider) {
    return wrapper(
      <img
        src={pictures[currentImageIndex][urlAttribute]}
        className="gallery__loader"
        srcSet={srcSet(pictures[currentImageIndex].variants, canWebp())}
        title={title}
        alt={altTag(currentImageIndex)}
        loading="lazy"
      />,
    )
  }

  const hasMultiplePictures = pictures.length > 1
  const previousPictureIndex = currentImageIndex === 0 ? pictures.length - 1 : currentImageIndex - 1
  const nextPictureIndex = currentImageIndex === pictures.length - 1 ? 0 : currentImageIndex + 1
  const pictureIndexesToRender = uniq([previousPictureIndex, currentImageIndex, nextPictureIndex])

  const renderedSlides = pictureIndexesToRender.map((currentSlideIndex) => {
    const picture = pictures[currentSlideIndex]

    const imageClassNames = classNames(
      'gallery__slider__slide',
      {
        'gallery__slider__slide--prev': hasMultiplePictures && currentSlideIndex === previousPictureIndex,
        'gallery__slider__slide--current': currentSlideIndex === currentImageIndex,
        'gallery__slider__slide--next': hasMultiplePictures && currentSlideIndex === nextPictureIndex,
        [`gallery__slider__slide--${slideDirection}`]: !!slideDirection,
      },
    )

    return (
      <img
        src={picture[urlAttribute]}
        className={imageClassNames}
        srcSet={srcSet(pictures[currentImageIndex].variants, canWebp())}
        title={title}
        alt={altTag(currentSlideIndex)}
        key={picture.id}
        loading="lazy"
      />
    )
  })

  const trackSlide = (direction: string) => {
    if (!trackGalleryUsage || !direction) {
      return
    }

    pushDataLayer({
      event: 'googleAnalyticsGenericEvent',
      eventCategory: 'galleryArrow',
      eventAction: `click${direction}`,
    })
  }

  const showPreviousSlide = (event: React.UIEvent<Element>) => {
    event.preventDefault()
    event.stopPropagation()

    setSlideDirection('current-to-next')
    setCurrentImageIndex(currentImageIndex <= 0 ? pictures.length - 1 : currentImageIndex - 1)
    trackSlide('Left')
  }

  const showNextSlide = (event: React.UIEvent<Element>) => {
    event.preventDefault()
    event.stopPropagation()

    setSlideDirection('current-to-prev')
    setCurrentImageIndex(currentImageIndex >= pictures.length - 1 ? 0 : currentImageIndex + 1)
    trackSlide('Right')
  }

  const touchStart: TouchEventHandler = (event) => {
    setTouchStartPointX(event.changedTouches[0].clientX)
  }

  const touchEnd: TouchEventHandler = (event) => {
    const mininmumSwipeDistance = 30
    const swipeDistance = touchStartPointX ? touchStartPointX - event.changedTouches[0].clientX : 0

    if (Math.abs(swipeDistance) < mininmumSwipeDistance) { return }

    if (swipeDistance < 0) {
      showPreviousSlide(event)
    } else {
      showNextSlide(event)
    }
  }

  const renderControls = () => (
    <div className="gallery__slider__controls" onTouchStart={touchStart} onTouchEnd={touchEnd}>
      <div className="gallery__slider__controls__control" onClick={showPreviousSlide}>
        <Icon name="angle-left" />
      </div>

      <div className="gallery__slider__controls__control" onClick={showNextSlide}>
        <Icon name="angle-right" />
      </div>
    </div>
  )

  return wrapper(
    <div className="gallery__slider">
      {hasMultiplePictures && renderControls()}
      {renderedSlides}
    </div>,
  )
}

interface GalleryProps {
  pictures?: Array<Picture>
  initialPictureIndex?: number
  title?: string
  showSlider?: boolean
  trackGalleryUsage?: boolean
}

export default Gallery
