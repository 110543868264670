import { EventCategory, Picture as GraphqlPicture } from 'generated/types'
import { Picture as StorePicture } from 'store/types/ProviderProfilePage'

import { Modify } from 'helpers/typings'

type PartialGraphqlPicture = Modify<Pick<GraphqlPicture, '__typename'>, {
  eventCategories: Pick<EventCategory, 'id'>[]
}>

export const findPictureIndex = (
  pictures: Array<Partial<StorePicture>> | Array<PartialGraphqlPicture>,
  eventCategoryId: string | number | undefined,
) => {
  if (!eventCategoryId) { return 0 }

  const index = pictures?.findIndex((picture) => (
    isGraphqlPicture(picture)
      ? matchGraphqlPictureIndexByEventCategory(picture, eventCategoryId)
      : matchStorePictureIndexByEventCategory(picture, eventCategoryId)
  ))

  return index < 0 ? 0 : (index || 0)
}

const matchGraphqlPictureIndexByEventCategory = (
  picture: PartialGraphqlPicture,
  eventCategoryId: string | number,
) => {
  return picture.eventCategories?.find(({ id }) => id === eventCategoryId)
}

const matchStorePictureIndexByEventCategory = (
  picture: Partial<StorePicture>,
  eventCategoryId: string | number,
) => {
  return picture.eventCategories?.includes(Number(eventCategoryId))
}

const isGraphqlPicture = (picture: Partial<StorePicture> | PartialGraphqlPicture): picture is PartialGraphqlPicture => (
  (picture as Partial<GraphqlPicture>).__typename === 'Picture'
)
