import React from 'react'
import PropTypes from 'prop-types'

import Result from 'components/Result'
import PageHead from 'containers/PageHead'

import './index.less'

const Error = (props) => {
  const {
    error,
    headerData,
    statusCode,
  } = props

  const head = headerData && <PageHead headerData={headerData} />

  return (
    <>
      {head}
      <Result
        className="error--generic"
        icon="exclamation-triangle"
        title={`Error ${statusCode}`}
        subTitle={error?.message}
      />
    </>
  )
}

Error.propTypes = {
  headerData: PropTypes.object,
  statusCode: PropTypes.number.isRequired,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      message: PropTypes.string,
    }),
  ]),
}

export default Error
