import React from 'react'
import PropTypes from 'prop-types'
import Head from 'next/head'
import { withRouter } from 'next/router'

import { addQueryStringParamsToUrl } from 'helpers/params'

import favicon from 'public/favicon.ico'

class PageHead extends React.PureComponent {
  renderAlternates = (alternates) => {
    return alternates.map((alternate, i) => {
      return <link
        rel="alternate"
        hrefLang={alternate.default_locale}
        href={alternate.path}
        key={i}
      />
    })
  }

  renderPagination () {
    const {
      page,
      totalPages,
      router,
    } = this.props

    if (!page || !totalPages) { return }

    const nextPage = page + 1
    const prevPage = page === 2 ? undefined : page - 1

    return (
      <>
        {page !== 1 && (
          <link rel="prev" href={addQueryStringParamsToUrl(router.asPath, { page: prevPage })} />
        )}
        {page !== totalPages && (
          <link rel="next" href={addQueryStringParamsToUrl(router.asPath, { page: nextPage })} />
        )}
      </>
    )
  }

  render () {
    const { headerData, children } = this.props

    return (
      <Head>
        <meta content="width=device-width, initial-scale=1" id="metaViewport" name="viewport"></meta>
        <link rel="shortcut icon" type="image/x-icon" href={favicon.src} />
        {headerData.title && <title>{headerData.title}</title>}
        {headerData.metaDescription && <meta name="description" content={headerData.metaDescription} />}
        {headerData.metaRobots && <meta name="robots" content={headerData.metaRobots} />}
        {headerData.canonicalUrl && <link rel="canonical" href={headerData.canonicalUrl} />}
        {headerData.alternate && this.renderAlternates(headerData.alternate)}
        {this.renderPagination()}
        {children}
      </Head>
    )
  }
}

PageHead.propTypes = {
  children: PropTypes.node,
  headerData: PropTypes.shape({
    title: PropTypes.string,
    metaDescription: PropTypes.string,
    metaRobots: PropTypes.string,
    canonicalUrl: PropTypes.string,
    alternate: PropTypes.array,
  }),
  page: PropTypes.number,
  totalPages: PropTypes.number,
  router: PropTypes.shape({
    asPath: PropTypes.string.isRequired,
  }).isRequired,
}

export default withRouter(PageHead)
