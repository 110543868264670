import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Icon from 'components/Icon'

import './index.less'

class Spinner extends React.PureComponent {
  render () {
    const {
      className,
      fullHeight,
      fullPageHeight,
      fixedFullHeight,
    } = this.props

    return (
      <div
        className={classNames(
          'load_spinner_eventinc',
          className,
          {
            'load_spinner_eventinc--full-height': fullHeight,
            'load_spinner_eventinc--full-page-height': fullPageHeight,
            'load_spinner_eventinc--fixed-full-height': fixedFullHeight,
          },
        )}
      >
        <Icon name="loading" />
      </div>
    )
  }
}

Spinner.propTypes = {
  className: PropTypes.string,
  // fullHeight only sets height of Spinner to 100%
  fullHeight: PropTypes.bool,
  // fullPageHeight sets height of Spinner to 100% - header height
  fullPageHeight: PropTypes.bool,
  // fixedFullHeight makes Spinner like a fixed overlay over the whole page
  fixedFullHeight: PropTypes.bool,
}

Spinner.defaultProps = {
  fullHeight: false,
  fullPageHeight: false,
}

export default Spinner
