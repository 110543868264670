import { canWebp, isMsie } from 'helpers/browser'
import { useSelector } from 'react-redux'
import { RootState } from 'store/reducer'
import { LayoutState } from 'store/types/Layout'

/* How to use:
 * import useBrowser from 'hooks/browser'
 * const { layout, isMsie, canWebp } = useBrowser()
*/
const useBrowser = () => {
  const layout = useSelector<RootState, LayoutState>(state => state.layout)

  return {
    layout,
    isMsie: () => isMsie(layout.userAgent),
    canWebp: () => canWebp(layout.userAgent),
  }
}

export default useBrowser
