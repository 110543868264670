import React from 'react'

import classNames from 'helpers/css'

import styles from './index.module.scss'

const Badge: React.FC<BadgeProps> = ({
  children,
  type = 'default',
  size = 'default',
  className,
}) => (
  <div className={classNames(styles, 'badge', `badge--${type}`, `badge--size-${size}`, className)}>{children}</div>
)

export type BadgeType = (
  'default' |
  'skew' |
  'blue-color' |
  'light-blue-color' |
  'peacock-color' |
  'peacock-sharp-color' |
  'error-color' |
  'business-color' |
  'success-color'|
  'attention-color' |
  'commission-free-color'
)

export interface BadgeProps {
  children: React.ReactNode,
  className?: string,
  type?: BadgeType,
  size?: 'small' | 'default',
}

export default Badge
