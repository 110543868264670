import React from 'react'
import classNames from 'classnames'

import FormattedPrice from 'components/FormattedPrice'
import FormattedMessage from 'components/FormattedMessage'

import './index.less'

const PriceBadge: React.FC<PriceBadgeProps> = (props) => {
  const {
    circa,
    per,
    price,
  } = props

  const isPriceLong = parseFloat(price) !== parseInt(price) || parseFloat(price).toString().length > 3

  return (
    <div className="price-badge">
      {circa && (
        <span className="price-badge__item price-badge__item--circa">
          <FormattedMessage id="priceBadge.circa" />
        </span>
      )}

      <span className={classNames('price-badge__price', { 'price-badge__price--long': isPriceLong })}>
        <FormattedPrice
          price={price}
          asInteger="auto"
          removeSpace={true}
        />
      </span>

      {per && (
        <span className="price-badge__item price-badge__item--per">
          <FormattedMessage id={`priceBadge.per.${per}`} />
        </span>
      )}
    </div>
  )
}

export interface PriceBadgeProps {
  circa: boolean
  per: 'person' | 'hour' | 'day'
  price: string
}

export default PriceBadge
