import React from 'react'

import Badge from 'components/Badge'
import FormattedMessage from 'components/FormattedMessage'
import { ProjectDashboardStatus } from 'generated/types'

import { projectDashboardStatusToBadgeType } from 'helpers/projects'

import './index.less'

const StatusBadge: React.FC<StatusBadgeProps> = (props) => {
  const { requestStatus } = props

  return (
    <Badge
      type={projectDashboardStatusToBadgeType[requestStatus]}
      className="provider-card__status-badge"
    >
      <b>
        <FormattedMessage id={`providerCard.statusBadge.${requestStatus}`} />
      </b>
    </Badge>
  )
}

interface StatusBadgeProps {
  requestStatus: ProjectDashboardStatus
}

export default StatusBadge
