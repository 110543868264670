import React, { isValidElement } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import classNames from 'classnames'

import Icon from 'components/Icon'

import './index.less'

class IconList extends React.PureComponent {
  isContentValid = (content) => (
    isValidElement(content) || (get(content, 'trim') && content.trim().length > 0)
  )

  renderIcon = ({ iconContent, icon }) => (
    iconContent ? <div className="icon icon-content">{iconContent}</div> : <Icon name={icon} />
  )

  renderFeature = ({ icon, iconContent, content, rightContent, className }, index) => {
    if (!this.isContentValid(content)) { return }

    return (
      <div
        key={index}
        className={classNames('icon-list__item', className)}
      >
        <div className="icon-list__content icon-list__content--left">
          {this.renderIcon({ iconContent, icon })}
          {content}
        </div>

        {rightContent && this.isContentValid(rightContent.content) && (
          <div className="icon-list__content icon-list__content--right">
            {this.renderIcon(rightContent)}
            {rightContent.content}
          </div>
        )}
      </div>
    )
  }

  render () {
    const {
      listData,
      className,
    } = this.props

    return (
      <div
        className={classNames(
          'icon-list',
          className,
        )}
      >
        {listData.map((item, index) => this.renderFeature(item, index))}
      </div>
    )
  }
}

IconList.propTypes = {
  listData: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.string,
    iconContent: PropTypes.string,
    content: PropTypes.node,
    rightContent: PropTypes.shape({
      icon: PropTypes.string,
      iconContent: PropTypes.string,
      content: PropTypes.node,
    }),
    className: PropTypes.string,
  })).isRequired,
  className: PropTypes.string,
}

export default IconList
