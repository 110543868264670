import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Icon from 'components/Icon'

import './Star.less'

const Star = ({ mode }) => (
  <div
    className={classNames(
      'rating__star',
      {
        'rating__star--full': mode === 'full',
        'rating__star--half': mode === 'half',
      },
    )}
  >
    <Icon name="star" />
    {mode === 'half' && <Icon name="star" className="rating__star__half-star" />}
  </div>
)

Star.propTypes = {
  mode: PropTypes.oneOf(['full', 'half']),
}

export default Star
