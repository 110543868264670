import { findPictureIndex } from 'helpers/providerPictures'
import { Provider, Room } from 'store/types/ProviderProfilePage'
import { Filters } from 'store/types/ProvidersFilter'

export const getMaximumRoomCapacity = (rooms: Room[]): number | undefined => {
  const maxGuests = rooms.flatMap(room => (
    room.seatingCapacities?.map(seatingCapacity => seatingCapacity.maxGuests) || 0
  ))

  if (maxGuests.length === 0) {
    return
  }

  return Math.max(...maxGuests)
}

export const getSuitableRoom = (filters: Filters, rooms: Room[]) => {
  const {
    seatingPlans = [],
    guestCount,
  } = filters

  const numberGuestCount = Number(guestCount)

  const suitableRooms = rooms.flatMap(room => (
    room.seatingCapacities?.filter(seatingCapacity => (
      seatingCapacity.minGuests <= numberGuestCount &&
      seatingCapacity.maxGuests >= numberGuestCount &&
      (seatingPlans.length === 0 || seatingPlans.includes(seatingCapacity.seatingPlan))
    ))
  ))

  const sortedResult = suitableRooms.sort((a, b) => !a || !b ? 0 : a.maxGuests - b.maxGuests)

  return sortedResult?.[0]
}

export const getDefaultProviderPicture =
  (provider: Provider, eventCategoryId: string | number, canWebp?: boolean) => {
    const urlAttribute = canWebp ? 'webp_url' : 'url'

    return provider.pictures[findPictureIndex(provider.pictures, eventCategoryId)]?.[urlAttribute]
  }
