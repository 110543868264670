import { BadgeType } from 'components/Badge'
import { Project, ProjectDashboardStatus } from 'generated/types'
import { getFilterUrl } from 'helpers/filters'

export const projectBrowsePathWithFilters = (
  project: Pick<Project, 'id' | 'budgetMax' | 'guestCount' | 'browsePath'>,
) => {
  const maxPrice = (project.budgetMax && project.guestCount)
    ? Math.trunc(parseInt(project.budgetMax) / project.guestCount)
    : undefined

  return getFilterUrl({
    pathname: project.browsePath || undefined,
    filter: {
      guestCount: project.guestCount,
      maxPrice,
      projectId: project.id,
    },
  })
}

export const projectDashboardStatusToBadgeType: {[status in ProjectDashboardStatus]: BadgeType} = {
  cancelled: 'error-color',
  declined: 'error-color',
  declined_by_provider: 'error-color',
  proposed: 'blue-color',
  booked: 'success-color',
  price_indication: 'blue-color',
  offer_accepted: 'success-color',
  offer_binding_without_option: 'blue-color',
  offer_first_option: 'blue-color',
  offer_second_option: 'blue-color',
  interesting: 'attention-color',
  open: 'light-blue-color',
  waiting: 'attention-color',
}
