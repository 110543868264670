import React from 'react'
import PropTypes from 'prop-types'
import { useIntl, FormattedNumber } from 'react-intl'
import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'

const FormattedPrice = (props) => {
  const {
    price,
    asInteger,
    removeSpace,
    currency,
    className,
  } = props

  const intl = useIntl()
  const router = useRouter()
  const currentUser = useSelector(state => state.currentUser)
  let fractionDigits = asInteger ? 0 : 2

  if (asInteger === 'auto' && parseFloat(price) !== parseInt(price)) {
    fractionDigits = 2
  }

  const options = {
    style: 'currency',
    currency: currency ||
      currentUser?.preferredPartner?.settings?.currency ||
      intl.formatMessage({ id: 'common.currencyName' }),
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  }

  if (!Intl || !Intl.NumberFormat) {
    // Wrong number formatting with React-Intl-Redux as it's currently initialized, but fallback for older browsers
    return (
      <FormattedNumber
        value={price}
        {...options}
      />
    )
  }

  let formattedNumber = new Intl.NumberFormat(
    router.locale,
    options,
  ).format(price)

  if (removeSpace) {
    formattedNumber = formattedNumber.replace('\xa0', '') // Non-breaking space
  }

  return <span className={className}>{formattedNumber}</span>
}

FormattedPrice.propTypes = {
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  asInteger: PropTypes.oneOf([true, false, 'auto']),
  removeSpace: PropTypes.bool,
  currency: PropTypes.string,
  className: PropTypes.string,
}

export default FormattedPrice
