import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import FormattedMessage from 'components/FormattedMessage'
import FormattedPrice from 'components/FormattedPrice'

import { getSuitableRoom, getMaximumRoomCapacity } from 'helpers/providerInfos'

import AbstractProviderDetails from './AbstractProviderDetails'

const mapStateToProps = (state) => ({
  filters: state.providersFilter.filters,
})

const ProviderDetails = (props) => {
  const {
    intl,
    provider,
    filters,
    eventCategoryId,
  } = props

  const {
    locationTypeInfo,
    priceIndications = [],
    isSteadyCaterer,
    rooms = [],
  } = provider

  const catering = intl.formatMessage(
    { id: isSteadyCaterer ? 'properties.amenities.steady_caterer' : 'properties.amenities.negative.steady_caterer' },
  )

  let priceIndication
  const selectedCategoryId = eventCategoryId || parseInt(filters.occasionFilter)

  if (selectedCategoryId) {
    priceIndication = priceIndications.find(indication => indication.eventCategory.id === selectedCategoryId)
  } else {
    priceIndication = [...priceIndications].sort((a, b) => parseFloat(a.min) - parseFloat(b.min))[0]
  }

  priceIndication = priceIndication && (
    <>
      {parseInt(priceIndication.min)}
      {'–'}
      <FormattedPrice
        price={priceIndication.max}
        asInteger />
      {' '}
      <FormattedMessage id="common.perPerson" />
    </>
  )

  const renderCapacity = (guests) => (
    <>
      {guests}
      {' '}
      <FormattedMessage id="common.guests" />
    </>
  )

  let guests

  if (filters.guestCount) {
    guests = renderCapacity(getSuitableRoom(filters, rooms)?.maxGuests)
  } else {
    const maximumRoomCapacity = getMaximumRoomCapacity(rooms)

    guests = maximumRoomCapacity && renderCapacity(maximumRoomCapacity)
  }

  return (
    <AbstractProviderDetails
      locationTypeInfo={locationTypeInfo}
      guests={guests}
      catering={catering}
      price={priceIndication}
    />
  )
}

ProviderDetails.propTypes = {
  intl: PropTypes.object.isRequired,
  eventCategoryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  filters: PropTypes.shape({
    occasionFilter: PropTypes.number,
    guestCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    seatingPlans: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  }).isRequired,
  provider: PropTypes.shape({
    locationTypeInfo: PropTypes.string,
    isSteadyCaterer: PropTypes.bool,
    rooms: PropTypes.array,
    room: PropTypes.arrayOf(PropTypes.shape({
      seatingCapacities: PropTypes.arrayOf(PropTypes.shape({
        minGuests: PropTypes.number,
        maxGuests: PropTypes.number,
        seatingPlan: PropTypes.string,
      })),
    })),
    priceIndications: PropTypes.arrayOf(PropTypes.shape({
      min: PropTypes.string,
      eventCategory: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }).isRequired,
    })),
  }),
}

export default injectIntl(connect(mapStateToProps)(ProviderDetails))
