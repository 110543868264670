import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Icon from 'components/Icon'

import './index.less'

const Result = (props) => (
  <div className={classNames('result', props.className)}>
    {props.icon && (
      <Icon name={props.icon} className="result__icon" />
    )}

    <h2 className="result__title">
      {props.title}
    </h2>

    {props.subTitle && (
      <p className="result__sub-title">
        {props.subTitle}
      </p>
    )}

    {props.extra && (
      <div className="result__extra">
        {props.extra}
      </div>
    )}
  </div>
)

Result.propTypes = {
  className: PropTypes.string,
  title: PropTypes.node.isRequired,
  subTitle: PropTypes.node,
  icon: PropTypes.string,
  extra: PropTypes.node,
}

export default Result
