import React from 'react'

import FlashMessage from 'components/FlashMessage'
import Footer from 'components/Footer'
import BasicFooter from 'components/Footer/basicFooter'
import classNames from 'classnames'

import './layout.less'

const HeadlessLayout: React.FC<HeadlessLayoutProps> = (props) => {
  const {
    children,
    flashMessages,
    basicFooter,
  } = props

  return (
    <>
      {flashMessages?.notice && (
        <FlashMessage title={flashMessages.notice} type="info" icon="info-circle" />
      )}
      <div className={classNames(
        basicFooter ? 'layout-container' : '',
      )}>
        {children}
        {basicFooter ? (
          <BasicFooter basicFooter={basicFooter} />
        ) : (
          <Footer />
        )}
      </div>
    </>
  )
}

interface HeadlessLayoutProps {
  flashMessages?: { notice: string },
  children: React.ReactNode,
  basicFooter: boolean,
}

export default HeadlessLayout
