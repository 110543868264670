import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { connect } from 'react-redux'

import FormattedMessage from 'components/FormattedMessage'
import Icon from 'components/Icon'

import './index.less'

const mapStateToProps = (state) => ({
  requestedProviders: state.providers.requestedProviders,
})

class RequestedProviderNotice extends React.PureComponent {
  renderNoticeText (requestedOn) {
    if (requestedOn >= moment().startOf('day').valueOf()) {
      return <FormattedMessage id="shared.requestedProviderNotice.requestedToday" />
    }

    if (requestedOn >= moment().subtract(1, 'day').startOf('day').valueOf()) {
      return <FormattedMessage id="shared.requestedProviderNotice.requestedYesterday" />
    }

    return <FormattedMessage
      id="shared.requestedProviderNotice.requestedAt"
      values={{ date: moment(requestedOn).format('LL') }}
    />
  }

  render () {
    const {
      provider,
      requestedProviders,
    } = this.props

    if (!requestedProviders || requestedProviders.length === 0) { return null }

    const requestedOn = requestedProviders[provider.id]

    if (!requestedOn) { return null }

    return (
      <div className="requested-provider-notice">
        <Icon name="info-circle" />
        {this.renderNoticeText(requestedOn)}
      </div>
    )
  }
}

RequestedProviderNotice.propTypes = {
  provider: PropTypes.object.isRequired,
  requestedProviders: PropTypes.object,
}

RequestedProviderNotice.defaultProps = {
  requestedProviders: {},
}

export default connect(mapStateToProps)(RequestedProviderNotice)
