import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { FormattedNumber } from 'react-intl'

import Star from './Star'

import './index.less'

const RATING_SCALE = [0, 1, 2, 3, 4]

const Rating = ({ rating, className, short }) => (
  <div className={classNames('rating', className)}>
    <div className="rating__stars">
      {short
        ? <Star mode="full" />
        : RATING_SCALE.map((lowerBound) => {
          let mode

          if (parseFloat(rating) >= lowerBound + 0.8) {
            mode = 'full'
          } else if (parseFloat(rating) >= lowerBound + 0.3) {
            mode = 'half'
          }

          return (
            <Star key={lowerBound} mode={mode} />
          )
        })
      }
    </div>

    <div className="rating__value">
      <FormattedNumber value={parseFloat(rating)} minimumFractionDigits={1} maximumFractionDigits={1} />

      {short && (
        <span className="rating__value__info">
          {`/ ${RATING_SCALE.length}`}
        </span>
      )}
    </div>
  </div>
)

Rating.propTypes = {
  rating: PropTypes.string.isRequired,
  className: PropTypes.string,
  short: PropTypes.bool,
}

export default Rating
